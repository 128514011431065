import ApplicationController from './application_controller'
// import { initChoices } from '../plugins/init-choices'
import Choices from 'choices.js'

/* This is the custom StimulusReflex controller for the Rubric Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static target = ['categoriesForm']
  connect () {
    super.connect()
    // add your code here, if applicable
    this.initChoice()
  }

  initChoice() {
    const el = document.querySelector('select#question_rubric_rubric_id')
    console.log({el})
    this.dropdown = new Choices(el, {
      removeItems: true,
      removeItemButton: true,
      shouldSort: false,
      shouldSortItems: true,
      addItems: true,
      classNames: {
        containerInner: 'choices_custom_inner_single',
        input: 'choices_custom_input_single',
      }
    });
  }

  changeQuestionRubric(e) {
    console.log(e)
    if (confirm("Are you sure you want to assign a new rubric?")) {
      this.stimulate('Rubric#select_question_rubric', e.target)
    } else {
      // element.value = e.target.dataset.rubricId
      this.dropdown.setChoiceByValue(e.target.dataset.rubricId)
    }
  }

  afterReflex(element, reflex) {
    // initChoices()
    this.initChoice()
  }
}
