// import ApplicationController from './application_controller'
import AnswerQuestionsController from './answer_questions_controller'
import Swal from 'sweetalert2'

export default class extends AnswerQuestionsController {
  static targets = [ 'passage', 'container', 'submitBtn', 'invisibleButton' ]
  static values = {
    expired: Boolean,
    timerHasRestarted: Boolean,
  }
  connect () {
    super.connect()
    this.renderKatex()
    this.hideSidebar()
  }

  answerMathFrq(e) {
    // TODO need to pass data what kind of test type / section type is this
    const target = e.currentTarget;
    const { value } = target;
    const valid = this._validateArrangement(value);
    if (valid) {
      this.stimulate('Lessons#answer_math_frq', target)
    }
  }

  passageTargetConnected(el) {
    // console.log('passageTargetConnected', el)
    // Not used, use after reflex instead
    this.renderKatex()
  }

  trySubmitSection(e) {
    console.log('try submit lesson section, e =>', e)
    const { dataset } = e.target
    console.log(dataset)
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    this.loading()

    fetch(`/student_lessons/${dataset.studentLessonId}/try_submit`, {headers: {"X-CSRF-TOKEN": csrf}})
      .then(res => res.json())
      .then(res => {
        console.log('res', res)
        if (res.msg == 'ok') {
          Swal.fire({
            text: `Submit your answers for this lesson?`,
            icon: 'warning',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#D64933',
            showCancelButton: true,
            // reverseButtons: true,
            confirmButtonText: 'Continue',
            confirmButtonColor: '#57A773',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (!result.isConfirmed) {
              this.loading('hide')
              this.restartTimer()
            } else {
              this.loading()
              this.submitSection(e, dataset.studentLessonId)
            }
          })
        } else {
          Swal.fire({
            title: 'STOP!',
            text: `${res.msg}\nAre you sure you want to submit your answers for this lesson?`,
            icon: 'error',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#57A773',
            showCancelButton: true,
            // reverseButtons: true,
            confirmButtonText: 'Continue',
            confirmButtonColor: '#D64933',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (!result.isConfirmed) {
              this.loading('hide')
              this.restartTimer()
            } else {
              // Swal.fire('Changes are not saved', '', 'info')
              Swal.fire({
                icon: 'warning',
                text: 'You have unanswered questions, and this action can NOT be reverted. Are you sure you want to submit?',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#57A773',
                showCancelButton: true,
                // reverseButtons: true,
                confirmButtonText: 'Continue',
                confirmButtonColor: '#D64933',
              }).then((res) => {
                if (res.isConfirmed) {
                  // confirm
                  console.log('confirmed')
                  this.loading()
                  this.submitSection(e, dataset.studentLessonId)
                } else {
                  this.loading('hide')
                  this.restartTimer()
                }
              })
            }
          })
        }
      })
  }

  submitSection(e, studentLessonId) {
    this.stimulate('Lessons#submit_section', e.target, studentLessonId).then(res => {
      this.loading('hide')
      this.clearTimer()
      window.location.href = `/courses/${e.target.dataset.courseSlug}`
    })
  }

  displayTimeExpiredAlert() {
    Swal.fire({
      title: "Time is up!",
      text: `Your time for this lesson is up.`,
      icon: 'error',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#57A773',
      cancelButtonColor: '#D64933',
      confirmButtonText: 'Submit Answers',
      cancelButtonText: 'Keep working',
    }).then(res => {
      if (res.isConfirmed) {
        console.log('submit answers')
        if (this.hasInvisibleButtonTarget) this.invisibleButtonTarget.click()
      } else {
        console.log('keep working')
        // restart time
        this.restartTimer()
      }
    })
  }

  clearTimer() {
    console.log('CLEAR TIMER FROM LESSONS')
    this.dispatch('clearTimer')
  }

  restartTimer() {
    let el;
    if (this.hasInvisibleButtonTarget) el = this.invisibleButtonTarget
    if (el) {
      console.log('el found')
      const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      // this.loading()
      fetch(`/student_lessons/${el.dataset.studentLessonId}/check_expiry`, {headers: {"X-CSRF-TOKEN": csrf}})
        .then(res => res.json())
        .then(res => {
          console.log('res', res)
          if (res.is_expired && !res.restart_time) {
            this.stimulate('Lessons#restart_timer', el).then(res => {
              // here dispatch timer restart
              this.dispatch('restartTimer')
            })
          }
        })
    }

  }

  hideSidebar() {
    let showSidebar = localStorage.getItem('showSidebar')
    showSidebar = showSidebar !== null ? JSON.parse(showSidebar) : true
    console.log('showSidebar in exams controller', showSidebar)
    if (showSidebar) {
      console.log('menu is on, hide it')
      this.dispatch('hideSidebar')
    }
  }

  afterReflex(reflex, element) {
    // console.log('afterreflex, rendering katex')
    this.renderKatex()
  }
}
